import React, { useEffect, useRef } from 'react'
import { useNot } from 'react-hooks-helper'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as headerStyles from '../styles/modules/header.module.scss'
import Logo from './logo'
import Hamburger from './hamburger'

const nav = [
  { to: '/about/', text: 'About' },
  { to: '/services/', text: 'Services' },
  { to: '/resources/', text: 'Resources' },
  { to: '/blog/', text: 'Blog' },
  { to: '/shop-goblin/', text: 'Shop' },
  { to: '/contact/', text: 'Contact' },
]

const Header = () => {
  const menuRef = useRef(null)
  const buttonRef = useRef(null)
  const [isActive, toggleActive] = useNot(false)

  useEffect(() => {
    const clickOffMenu = (e) => {
      if (
        menuRef.current !== null &&
        buttonRef !== null &&
        !menuRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        toggleActive()
      }
    }

    // Listen for clicks outside the menu to close the menu,
    // but only if the menu is already open.
    if (isActive) {
      window.addEventListener('click', clickOffMenu)
    }

    // Unset event listener for cleanup.
    return () => {
      window.removeEventListener('click', clickOffMenu)
    }
  }, [isActive])

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.logo}>
        <Logo reverse width={120} />
        <Logo reverse width={160} />
      </div>
      <div className={headerStyles.name}>
        <div className="h1">Kyah Morrissette</div>
        <p>Psychic Intuitive</p>
      </div>
      <button
        className={headerStyles.hamburger}
        type="button"
        ref={buttonRef}
        onClick={(e) => {
          e.preventDefault()
          toggleActive()
        }}
      >
        Menu
        <br />
        <Hamburger />
      </button>
      <nav
        ref={menuRef}
        className={`${headerStyles.nav} ${isActive ? headerStyles.active : ''}`}
      >
        {nav.map(({ to, text }, index) => (
          <Link to={to} key={index}>
            {text}
          </Link>
        ))}
        <div className={headerStyles.mrFelix} id="mr-felix">
          <StaticImage
            src="../images/mr-felix-with-shadow.png"
            alt="Mr Felix"
            layout="constrained"
            width={200}
            aria-hidden
            placeholder="none"
          />
        </div>
      </nav>
    </header>
  )
}

export default Header
