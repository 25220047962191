import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Success = () => (
  <Layout>
    <Seo title="Contact" />
    <article>
      <h1>Success</h1>
      <div>
        <p>Thanks! You'll hear from Kyah soon!</p>
      </div>
    </article>
  </Layout>
)

export default Success
