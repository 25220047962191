// extracted by mini-css-extract-plugin
export var activeLayout = "header-module--active-layout--3vi3s";
export var sidebarImage = "header-module--sidebar-image--2avSg";
export var image = "header-module--image--3XIEd";
export var block = "header-module--block--1zqSE";
export var header = "header-module--header--304Nf";
export var logo = "header-module--logo--WT1IH";
export var name = "header-module--name--11rSK";
export var hamburger = "header-module--hamburger--3f0KZ";
export var mrFelix = "header-module--mr-felix--24PRO";
export var nav = "header-module--nav--3aesk";
export var active = "header-module--active--12viv";