import { StaticImage } from 'gatsby-plugin-image'
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import '@reach/skip-nav/styles.css'
import React from 'react'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Header from './header'

const Layout = ({ children, inactive }) => (
  <TransitionState>
    {({ transitionStatus }) => (
      <div className={`layout ${transitionStatus === 'exiting' ? 'exit' : ''}`}>
        <SkipNavLink style={{ zIndex: 999 }} />
        <StaticImage
          className="background"
          src="../images/dead-leaf-texture.jpeg"
          alt=""
          loading="eager"
          backgroundColor="#e96223"
          placeholder="dominantColor"
        />
        <Header />
        <main className={`${inactive ? '' : 'active-layout'}`}>
          <SkipNavContent />
          {children}
        </main>
      </div>
    )}
  </TransitionState>
)

export default Layout
