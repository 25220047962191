import React from 'react'

const Hamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 6 16 12">
    <defs />
    <path
      d="M5 6h14c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm0 5h14c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm0 5h14c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1z"
      fill="#fcfbbb"
      fillRule="evenodd"
    />
  </svg>
)

export default Hamburger
